import React, { useEffect, useMemo, useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  Clock,
  XCircle,
  ClipboardCheck,
  MapPin,
  Calendar,
  CreditCard,
  Star,
  ChevronLeft,
  ChevronRight,
  X,
} from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import GetBookingStatus from "../../helpers/getBookingStatus";
import { apiClient } from "../../actions/api";
import {toast, Toaster} from "react-hot-toast";
import { BOOKINGS, SALONS } from "../../constants/actions";
import { FetchData } from "../../actions/data";
import FormateDateRange from "../../helpers/FormateDateRange";

function DashMain() {
  const [expandedItems, setExpandedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [cancelModal, setCancelModal] = useState({
    isOpen: false,
    bookingId: null,
    reason: "",
  });
  const itemsPerPage = 5;
  const maxVisibleButtons = 5;

  const [statistics, setStatistics] = useState({
    attente: "00",
    annulee: "00",
    termine: "00",
  });

  const bookings = useSelector((state) => state?.data?.bookings);
  const client = useSelector((state) => state?.auth?.client);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchData(`/clientBookings/${client?.id}`, BOOKINGS));
  }, []);

  useEffect(() => {
    if (bookings?.length) {
      const stats = { attente: 0, annulee: 0, termine: 0 };

      bookings.forEach((booking) => {
        const { availability, cancel } = booking;
        const bookingStatus = GetBookingStatus(
          availability?.date,
          availability?.start_time,
          availability?.end_time,
          1,
          cancel
        );

        switch (bookingStatus.type) {
          case "En Attente":
            stats.attente += 1;
            break;
          case "Annulée":
            stats.annulee += 1;
            break;
          case "Terminé":
            stats.termine += 1;
            break;
          default:
            break;
        }
      });

      setStatistics({
        attente: stats.attente.toString().padStart(2, "0"),
        annulee: stats.annulee.toString().padStart(2, "0"),
        termine: stats.termine.toString().padStart(2, "0"),
      });
    }
  }, [bookings]);

  const toggleItem = (index) => {
    setExpandedItems((prev) =>
      prev.includes(index)
        ? prev.filter((item) => item !== index)
        : [...prev, index]
    );
  };

  // Filter bookings based on active filter
  const filteredBookings = useMemo(() => {
    if (!bookings) return [];

    return bookings.filter((booking) => {
      const bookingStatus = GetBookingStatus(
        booking?.availability?.date,
        booking?.availability?.start_time,
        booking?.availability?.end_time,
        1,
        booking?.cancel
      );

      if (activeFilter === null) return true;

      switch (activeFilter) {
        case "En Attente":
          return bookingStatus.type === "En Attente";
        case "Annulée":
          return bookingStatus.type === "Annulée";
        case "Terminé":
          return bookingStatus.type === "Terminé";
        default:
          return true;
      }
    });
  }, [bookings, activeFilter]);

  const totalPages = Math.ceil(filteredBookings?.length / itemsPerPage);

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    setVisiblePages(pages);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  const loadMoreItems = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
    if (currentPage + 1 >= totalPages) {
      setShowLoadMore(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
    setShowLoadMore(totalPages > 1);
  }, [filteredBookings, totalPages]);

  const currentItems = useMemo(() => {
    const sortedBookings = [...filteredBookings].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    // For "Voir plus" functionality, we want to show all items up to the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    return sortedBookings.slice(0, indexOfLastItem);
  }, [currentPage, filteredBookings]);

  // Stat Card with filter functionality
  const StatCard = ({ icon, title, count, bgColor }) => (
    <div
      className={`${bgColor} p-3 rounded-xl shadow-sm flex flex-col items-center justify-center space-y-1 cursor-pointer 
      ${
        activeFilter === title ? "border-1 border-orange-700 !bg-[#492602]" : ""
      }
      hover:opacity-80 transition-all`}
      onClick={() => setActiveFilter(activeFilter === title ? null : title)} // Toggle active filter
    >
      <div
        className={`${
          activeFilter === title ? "bg-orange-50" : "bg-white"
        } p-2 rounded-full`}
      >
        {icon}
      </div>
      <p
        className={`${
          activeFilter === title ? "text-white" : "text-gray-600"
        } text-xs text-center`}
      >
        {title}
      </p>
      <h3
        className={`${
          activeFilter === title ? "text-white" : "text-gray-600"
        } text-lg font-bold`}
      >
        {count}
      </h3>
    </div>
  );

  // Open cancel modal
  const openCancelModal = (bookingId) => {
    setCancelModal({
      isOpen: true,
      bookingId,
      reason: "",
    });
  };

  // Close cancel modal
  const closeCancelModal = () => {
    setCancelModal({
      isOpen: false,
      bookingId: null,
      reason: "",
    });
  };

  // Handle booking cancellation
  const handleCancel = (id, reason) => {
    let formData = {
      booking_id: id,
      user_id: client?.id,
      cancellation_reason: reason,
    };
    try {
      apiClient()
        .post("/cancel-booking", formData)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message);
            dispatch(FetchData(`/clientBookings/${client?.id}`, BOOKINGS));
            closeCancelModal();
          } else {
            toast.error(res.data.message);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  // Submit cancellation
  const submitCancellation = () => {
    if (!cancelModal.reason.trim()) {
      toast.error("Veuillez entrer une raison d'annulation");
      return;
    }
    handleCancel(cancelModal.bookingId, cancelModal.reason);
  };

  return (
    <div className="min-h-screen pt-10 pb-20 w-full max-w-screen-lg mx-auto">
      {/* Statistics Section */}
      <Toaster />
      <div className="px-4 mb-6">
        <div className="grid grid-cols-3 gap-3">
          <StatCard
            icon={<Clock className="w-6 h-6 text-yellow-500" />}
            title="En Attente"
            count={statistics.attente}
            bgColor="bg-yellow-50"
          />
          <StatCard
            icon={<XCircle className="w-6 h-6 text-red-500" />}
            title="Annulée"
            count={statistics.annulee}
            bgColor="bg-red-50"
          />
          <StatCard
            icon={<ClipboardCheck className="w-6 h-6 text-blue-500" />}
            title="Terminé"
            count={statistics.termine}
            bgColor="bg-blue-50"
          />
        </div>
      </div>

      {/* Reservations Section */}
      <div className="space-y-4 px-4">
        {currentItems.map((booking, index) => (
          <ReservationCard
            key={booking.id}
            booking={booking}
            isExpanded={expandedItems.includes(index)}
            onToggle={() => toggleItem(index)}
            onCancel={() => openCancelModal(booking.id)}
          />
        ))}
      </div>

      {/* Voir Plus Button */}
      {showLoadMore && filteredBookings.length > itemsPerPage && (
        <div className="flex justify-center mt-6">
          <button
            onClick={loadMoreItems}
            className="bg-orange-500 text-white px-6 py-2 rounded-lg hover:bg-orange-600 transition-colors flex items-center"
          >
            Voir plus
            <ChevronDown className="ml-2 w-4 h-4" />
          </button>
        </div>
      )}

      {/* Cancellation Modal */}
      {cancelModal.isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4">
          <div className="bg-white rounded-xl w-full max-w-md p-5 shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold text-gray-800">
                Annuler la réservation
              </h3>
              <button
                onClick={closeCancelModal}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            
            <div className="mb-4">
              <p className="text-gray-600 mb-2">
                Veuillez indiquer la raison de l'annulation:
              </p>
              <textarea
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-200 transition-all"
                rows="4"
                placeholder="Raison d'annulation..."
                value={cancelModal.reason}
                onChange={(e) =>
                  setCancelModal({ ...cancelModal, reason: e.target.value })
                }
              ></textarea>
            </div>
            
            <div className="flex justify-end space-x-3">
              <button
                onClick={closeCancelModal}
                className="px-4 py-2 border border-gray-300 rounded-lg text-gray-600 hover:bg-gray-50 transition-colors"
              >
                Annuler
              </button>
              <button
                onClick={submitCancellation}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const ReservationCard = ({ booking, isExpanded, onToggle, onCancel }) => {
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [review, setReview] = useState("");
  const [selectedRating, setSelectedRating] = useState(null);
  const client = useSelector((state) => state?.auth?.client);

  const dispatch = useDispatch();

  const handleRatingClick = (selectedRating) => {
    setSelectedRating(selectedRating);
  };

  const handleReviewSubmit = async (item) => {
    try {
      await apiClient().post("/reviews", {
        user_id: item?.user_id,
        admin_id: item?.availability?.salon?.user_id,
        employee_id: item?.availability?.employee?.id,
        booking_id: item?.id,
        rating: selectedRating,
        comment: review,
      });
      toast.success(
        "Votre avis a été envoyé avec succès. Merci pour votre retour !"
      );
      setIsReviewOpen(false);
      setSelectedRating(null);
      setReview("");
      dispatch(FetchData("/completedSalons", SALONS));
      dispatch(FetchData(`/clientBookings/${client?.id}`, BOOKINGS));
    } catch (err) {
      console.error(err);
    }
  };

  const renderStatusBadge = (status) => {
    const statusStyles = {
      Acceptée: "bg-green-100 text-green-800",
      Annulée: "bg-red-100 text-red-800",
      Terminé: "bg-blue-100 text-blue-800",
      "En Attente": "bg-yellow-100 text-yellow-800",
    };

    return (
      <span
        className={`px-2 py-1 rounded-full text-xs font-medium ${
          statusStyles[status] || "bg-gray-100 text-gray-800"
        }`}
      >
        {status}
      </span>
    );
  };
  
  const status =
    booking?.availability?.date &&
    GetBookingStatus(
      booking?.availability?.date,
      booking?.availability?.start_time,
      booking?.availability?.end_time,
      1,
      booking?.cancel
    );

  const canCancel = status?.type === "En Attente";

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden border border-gray-100">
      <div
        className="flex justify-between items-center p-4 cursor-pointer"
        onClick={onToggle}
      >
        <div>
          <h3 className="font-bold text-lg text-gray-800">#{booking.id}</h3>
          <p className="text-sm text-gray-500">
            {booking.availability.salon.name}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          {renderStatusBadge(status?.type || "Acceptée")}
          {isExpanded ? <ChevronUp /> : <ChevronDown />}
        </div>
      </div>

      {isExpanded && (
        <div className="p-4 border-t space-y-4">
          <div className="flex items-center space-x-3 bg-gray-50 p-3 rounded-lg">
            <MapPin className="text-gray-500 w-6 h-6" />
            <div>
              <p className="font-semibold text-gray-800">
                {booking.availability.salon.name}
              </p>
              <p className="text-sm text-gray-500">
                {booking.availability.salon.address},{" "}
                {booking.availability.salon.city}
              </p>
            </div>
          </div>

          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <Calendar className="text-gray-500 w-5 h-5" />
              <span className="text-gray-700">
                {booking?.availability?.date &&
                  FormateDateRange(
                    booking?.availability?.date,
                    booking?.availability?.start_time,
                    booking?.availability?.end_time
                  )}
              </span>
            </div>
            <div className="flex items-center space-x-3">
              <Star className="text-gray-500 w-5 h-5" />
              <span className="text-gray-700">
                {booking?.services?.map((service) => service?.name)?.join(", ")}
              </span>
            </div>
            <div className="flex items-center space-x-3">
              <CreditCard className="text-gray-500 w-5 h-5" />
              <span className="text-gray-700 font-semibold">
                {booking?.payment?.amount} Dhs
              </span>
            </div>
          </div>

          {/* Cancel Button */}
          {canCancel && (
            <div className="flex justify-end mt-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onCancel();
                }}
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg flex items-center transition-colors"
              >
                <XCircle className="mr-2 w-4 h-4" />
                Annuler
              </button>
            </div>
          )}

          {status?.type === "Terminé" && !booking?.reviews?.length > 0 ? (
            <div className="bg-gray-50 p-3 rounded-lg">
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => setIsReviewOpen(!isReviewOpen)}
              >
                <h5 className="text-lg font-semibold text-gray-800">
                  Laissez votre avis
                </h5>
                {isReviewOpen ? <ChevronUp /> : <ChevronDown />}
              </div>

              {isReviewOpen && (
                <div className="mt-4 space-y-3">
                  <textarea
                    className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-200 transition-all"
                    rows="3"
                    placeholder="Votre commentaire..."
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                  ></textarea>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <button
                          key={star}
                          className={`text-2xl focus:outline-none transition-colors ${
                            star <= selectedRating
                              ? "text-yellow-400"
                              : "text-gray-300"
                          }`}
                          onClick={() => handleRatingClick(star)}
                        >
                          ★
                        </button>
                      ))}
                    </div>

                    <button
                      onClick={() => handleReviewSubmit(booking)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
                      disabled={!selectedRating}
                    >
                      Soumettre
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            status?.type === "Terminé" && (
              <div className="bg-gray-50 p-3 rounded-lg">
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() => setIsReviewOpen(!isReviewOpen)}
                >
                  <h5 className="text-lg font-semibold text-gray-800">
                    Avis ajouté
                  </h5>
                  {isReviewOpen ? <ChevronUp /> : <ChevronDown />}
                </div>

                {isReviewOpen && (
                  <div className="mt-4 space-y-3">
                    <textarea
                      className="w-full p-3 border rounded-lg bg-white"
                      rows="3"
                      value={booking?.reviews[0]?.comment}
                      readOnly
                    ></textarea>

                    <div className="flex items-center">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <button
                          key={star}
                          className={`text-2xl focus:outline-none transition-colors ${
                            star <= booking?.reviews[0]?.rating
                              ? "text-yellow-400"
                              : "text-gray-300"
                          }`}
                        >
                          ★
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default DashMain;