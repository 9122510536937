import "./index.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import MainNavigator from "./navigation";
import { PersistGate } from "redux-persist/integration/react";
import { UpdateIpAddress } from "./components";
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HelmetProvider>
        <I18nextProvider i18n={i18n}>
          <MainNavigator />
          <UpdateIpAddress />
        </I18nextProvider>
      </HelmetProvider>
    </PersistGate>
  </Provider>
);
