import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Menu, X, User, Wallet } from 'lucide-react';
import { BALANCE_USER, BASE_URL_IMAGE } from "../../constants/actions";
import { ProfileImg } from "../../assets/images";
import { apiClient } from "../../actions/api";
import logo from "../../assets/img/new-logo-color.png"
function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const auth = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const client = useSelector((state) => state?.auth?.client);
  const balance = useSelector((state) => state?.auth?.balance);
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleMenuToggle = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  useEffect(() => {
    if (client?.id) {
      apiClient()
        .get(`/user-balance/${client.id}/balance`)
        .then((res) => {
          if (res.data.success) {
            dispatch({ type: BALANCE_USER, payload: res.data.balance });
          }
        });
    }
  }, [client?.id, dispatch]);

  const navLinks = [
    { path: "/", label: "Accueil" },
    { path: "/info-apropos", label: "À propos" },
    { path: "/sidelisting-page", label: "Salons" },
    { path: "/blog", label: "Blog" },
    { path: "/entreprise", label: "Pour votre entreprise" },
  ];

  return (
    <nav className="border-b border-gray-200 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          {/* Logo */}
          <div className="flex-shrink-0 flex items-center cursor-pointer" onClick={() => navigate("/")}>
            <img src={logo} alt="Logo" className="h-8 w-auto" />
          </div>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex lg:items-center lg:space-x-8">
            {navLinks.map((link) => (
              <Link
                key={link.path}
                to={link.path}
                className={`px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                  location.pathname === link.path
                    ? "text-orange-600 bg-orange-50"
                    : "text-gray-700 hover:text-orange-600 hover:bg-gray-50"
                }`}
              >
                {t(link.label)}
              </Link>
            ))}
          </div>

          {/* Desktop Auth Section */}
          <div className="hidden lg:flex lg:items-center lg:space-x-4">
            {auth?.client ? (
              <div 
                className="flex items-center space-x-4 cursor-pointer hover:bg-gray-50 rounded-lg px-4 py-2 transition-colors"
                onClick={() => navigate("/dashboard", { state: { selone: "dash" } })}
              >
                <div className="relative">
                  <img
                    src={`${BASE_URL_IMAGE}${auth.client.avatar}`}
                    alt="Profile"
                    className="h-10 w-10 rounded-full object-cover"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = ProfileImg;
                    }}
                  />
                  <div className="absolute bottom-0 right-0 h-3 w-3 bg-green-400 rounded-full border-2 border-white" />
                </div>
                <div className="flex flex-col">
                  <span className="text-sm font-medium text-gray-900">
                    {auth.client.first_name} {auth.client.last_name}
                  </span>
                  <div className="flex items-center text-sm text-gray-500">
                    <Wallet className="h-4 w-4 mr-1" />
                    <span>{balance ? balance?.toFixed(2) : 0} MAD</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex items-center space-x-4">
                <Link
                  to="/connexion"
                  className="text-gray-700 hover:text-orange-600 px-4 py-2 text-sm font-medium"
                >
                  {t("Connexion")}
                </Link>
                <Link
                  to="/register"
                  className="bg-orange-600 text-white hover:bg-orange-700 px-4 py-2 rounded-lg text-sm font-medium transition-colors"
                >
                  {t("S'inscrire")}
                </Link>
              </div>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="flex lg:hidden items-center">
            <button
              onClick={handleMenuToggle}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-orange-600 hover:bg-gray-50 transition-colors"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`lg:hidden ${isMenuOpen ? "block" : "hidden"}`}>
        <div className="px-2 pt-2 pb-3 space-y-1">
          {navLinks.map((link) => (
            <Link
              key={link.path}
              to={link.path}
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                location.pathname === link.path
                  ? "text-orange-600 bg-orange-50"
                  : "text-gray-700 hover:text-orange-600 hover:bg-gray-50"
              }`}
            >
              {t(link.label)}
            </Link>
          ))}
          
          {auth?.client ? (
            <div
              className="mt-4 px-3 py-2 flex items-center space-x-3 cursor-pointer hover:bg-gray-50 rounded-md transition-colors"
              onClick={() => navigate("/dashboard", { state: { selone: "dash" } })}
            >
              <img
                src={`${BASE_URL_IMAGE}${auth.client.avatar}`}
                alt="Profile"
                className="h-10 w-10 rounded-full"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = ProfileImg;
                }}
              />
              <div>
                <div className="text-sm font-medium text-gray-900">
                  {auth.client.first_name} {auth.client.last_name}
                </div>
                <div className="flex items-center text-sm text-gray-500">
                  <Wallet className="h-4 w-4 mr-1" />
                  <span>{balance?.toFixed(2)} MAD</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-4 px-3 space-y-2">
              <Link
                to="/connexion"
                className="block text-center w-full px-4 py-2 text-sm font-medium text-gray-700 hover:text-orange-600 hover:bg-gray-50 rounded-md transition-colors"
              >
                {t("Connexion")}
              </Link>
              <Link
                to="/register"
                className="block text-center w-full px-4 py-2 text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 rounded-md transition-colors"
              >
                {t("S'inscrire")}
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Header;