import { toast } from "react-toastify";
import { apiClient } from "./api";
import { CLEAR_RESERVATION } from "../constants/actions";

export const CreateDemande = (salonData, userData) => async (dispatch) => {
  try {
    const { data } = await apiClient().post(`/users`, { ...userData, role: "admin", status: false });
    await apiClient().post(`/salons`, { ...salonData, status: false, user_id: data?.id });
  } catch (error) {
    console.error("Registration Failed!", error); 
  }
};

export const FetchData = (uri, action) => async (dispatch) => {
  try {
    const res = await apiClient().get(uri);

    if (res) {
      dispatch({ type: action, payload: res.data });
    } else {
      console.warn("Unhandled URI:", uri);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};


export const clearReservation = () => {
  return {
    type: CLEAR_RESERVATION
  };
};

export const ResetPassword = (data) => async (dispatch) => {
  try {
    const response = await apiClient().post(`/update-password/${data?.id}`, data);
    if(response?.data?.error){
      toast.error(response?.data?.error);
      return false;
    }else if(response?.data?.success){
      toast.success(response?.data?.success);
      return true;
    }else{
      return;
    }
  } catch (error) {
    console.error("Registration Failed!", error); 
  }
};

export const signalComplaint = (data) => async (dispatch) => {
  try {
    await apiClient().post(`/complaints`, data);
    toast.success('Votre signal a bien été envoyé');
  } catch (error) {
    console.error("Registration Failed!", error);
  }
};

export const CancelReservation = (resId) => async (dispatch) => {
  try {
    await apiClient().put(`/bookings/${resId}`, {
      status: false,
    });
    toast.success('Votre réservation a été annulée avec succès.', { autoClose: 1000 });
  } catch (error) {
    console.error("Annulation de la réservation échouée !", error);
  }
};

export const RegisterGuest = (data) => async (dispatch) => {
  try {
    const response = await apiClient().post(`/guests`, data);
    return response?.data;
  } catch (error) {
    console.error("Création de compte invitée échouée !", error);
  }
};

export const CreateAvailability = (data) => async (dispatch) => {
  try {
    const response = await apiClient().post(`/availabilities`, data);
    return response?.data;
  } catch (error) {
    console.error("Création de availability échouée !", error);
  }
};

export const CreateBooking = (data) => async (dispatch) => {
  try {
    const response = await apiClient().post(`/bookings`, data);
    return response?.data;
  } catch (error) {
    console.error("Création de réservation échouée !", error);
  }
};

export const AttacheServicesToBoking = (bookingId, services) => async (dispatch) => {
  try {
    const requests = services?.map(service => 
      apiClient().post(`/bookings/${bookingId}/attach-service`, {
        service_id: service?.id,
        price: service?.pivot?.price,
        duration: service?.pivot?.duration,
      })
    );
    await Promise.all(requests);
  } catch (error) {
    console.error("Erreur lors de l'ajout des services :", error);
  }
};


export const CreatePayment = (data) => async (dispatch) => {
  try {
    const response = await apiClient().post(`/payments`, data);
    return response?.data;
  } catch (error) {
    console.error("Création de paiement échouée !", error);
  }
};

export const GetEmplAvailabilities = (id) => async (dispatch) => {
  try {
    const response = await apiClient().get(`/emplAvailabilities/${id}`);
    return response?.data;
  } catch (error) {
    console.error("Création de paiement échouée !", error);
  }
};

export const CheckTheAvailabilty = (availability) => async (dispatch) => {
  try {
    const response = await apiClient().post(`/checkAvailability`, availability);
    return response?.data?.result;
  } catch (error) {
    console.error("Cheking de availability échouée !", error);
  }
};

export const SendEmail = (data) => async (dispatch) => {
  try {
    const response = await apiClient().post(`/sendEmail`, data);
    return response?.data;
  } catch (error) {
    console.error("Sendding Email échouée !", error);
  }
};
