import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import Zoom from "react-medium-image-zoom";
import { MapPin, Star, Clock, BadgeCheck, ChevronRight } from "lucide-react";
import PlaceIcon from "../../assets/images/geoplace.png";
import HairCutIcon from "@mui/icons-material/ContentCut";
import HairColorIcon from "@mui/icons-material/Brush";
import FacialIcon from "@mui/icons-material/Face";
import PedicureIcon from "@mui/icons-material/Spa";
import MakeupIcon from "@mui/icons-material/FaceRetouchingNatural";
import MassageIcon from "@mui/icons-material/SelfImprovement";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Helmet } from "react-helmet-async";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./style.scss";

import { BASE_URL_IMAGE } from "../../constants/actions";
import {
  EmployeeRatings,
  ReviewFilter,
  ReviewFilterTwo,
  ReviewList,
  ServicesTabsComp,
} from "../../components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "leaflet/dist/leaflet.css";
import "react-medium-image-zoom/dist/styles.css";
import { apiClient } from "../../actions/api";
import { clearReservation } from "../../actions/data";

const icon = L.icon({
  iconUrl: PlaceIcon,
  iconSize: [35, 35],
});

const iconMap = {
  HairCutIcon: HairCutIcon,
  HairColorIcon: HairColorIcon,
  FacialIcon: FacialIcon,
  PedicureIcon: PedicureIcon,
  MakeupIcon: MakeupIcon,
  MassageIcon: MassageIcon,
  StarBorderIcon: StarBorderIcon,
};

function DetailPage() {
  const location = useLocation();
  const params = useParams();
  const id = location?.state?.id || params?.id;
  const salons = useSelector((state) => state?.data?.salons);
  const [salon, setSalon] = useState(null);
  const reservation = useSelector((state) => state?.reservation);
  const [totalPrice, setTotalPrice] = useState(0);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [selectedService, setSelectedService] = useState("All");
  const [selectedRating, setSelectedRating] = useState("All");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showNotFoundModal, setShowNotFoundModal] = useState(false);
  const dispatch = useDispatch()
  
  useEffect(() => {
    // Dispatch action to clear the reservation/cart
    if (dispatch && clearReservation) {
      dispatch(clearReservation());
    }
  }, [dispatch]);

  const mapPosition =
    salon?.latitude &&
    salon?.longitude &&
    !isNaN(parseFloat(salon?.latitude)) &&
    !isNaN(parseFloat(salon?.longitude))
      ? [parseFloat(salon?.latitude), parseFloat(salon?.longitude)]
      : [0, 0];

  useEffect(() => {
    const fetchSalonData = async () => {
      if (!id) {
        setShowNotFoundModal(true);
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(false);

      // First check if salon is in Redux store
      const salonFromState = salons?.find((sal) => sal?.id === parseInt(id));

      if (salonFromState) {
        setSalon(salonFromState);
        setLoading(false);
        return;
      }

      // If not in Redux store, fetch from API
      try {
        const response = await apiClient().get(`/salons/${id}`);
        if (response.ok) {
          const data = await response.json();
          setSalon(data);
        } else {
          setShowNotFoundModal(true);
          setError(true);
        }
      } catch (error) {
        console.error("Failed to fetch salon:", error);
        setError(true);
        setShowNotFoundModal(true);
      } finally {
        setLoading(false);
      }
    };

    fetchSalonData();
  }, [id, salons]);

  useEffect(() => {
    if (reservation?.services?.length) {
      const total = reservation?.services?.reduce(
        (sum, service) => sum + parseFloat(service?.pivot?.price),
        0
      );

      setTotalPrice(total.toFixed(2));
    } else {
      setTotalPrice(0); // Reset total price when cart is empty
    }
  }, [reservation]);

  const handleFilter = () => {
    let filtered = salon?.user?.reviews || [];

    if (selectedService !== "All") {
      filtered = filtered?.filter((review) => {
        const employee = salon?.employees?.find(
          (emp) => emp?.id === review?.employee_id
        );
        return employee?.services?.some(
          (s) => s.id === parseInt(selectedService)
        );
      });
    }

    if (selectedRating !== "All") {
      filtered = filtered?.filter(
        (review) => review?.rating === parseInt(selectedRating)
      );
    }

    setFilteredReviews(filtered);
  };

  useEffect(() => {
    handleFilter();
  }, [selectedService, selectedRating, salon]);

  const getStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const totalStars = 5;

    return {
      fullStars,
      hasHalfStar,
      emptyStars: totalStars - fullStars - (hasHalfStar ? 1 : 0),
    };
  };

  const avgRating = parseFloat(salon?.average_rating) || 0;
  const { fullStars, hasHalfStar, emptyStars } = getStars(avgRating);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const metaTitle = `${salon?.name || "Salon Details"}`;
  const metaDescription = salon?.description
    ? `${salon.description.replace(/<[^>]*>?/gm, "").substring(0, 155)}...`
    : "Discover our beauty salon services, team, and location.";
  const metaImage =
    salon?.album?.length > 0
      ? `${BASE_URL_IMAGE}${salon.album[0].image}`
      : "https://your-default-image.jpg";

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="salon-detail-page mb-24">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:url" content={window.location.href} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />

        {/* Location metadata */}
        {salon?.latitude && salon?.longitude && (
          <>
            <meta
              name="geo.position"
              content={`${salon.latitude};${salon.longitude}`}
            />
            <meta
              name="geo.placename"
              content={`${salon.name}, ${salon.city}`}
            />
          </>
        )}
      </Helmet>

      {/* Not Found Modal */}
      <div
        className={`fixed inset-0 z-50 flex items-center justify-center p-4 ${
          showNotFoundModal ? "block" : "hidden"
        }`}
      >
        <div className="absolute inset-0 bg-black/40 backdrop-blur-sm"></div>

        <div className="relative w-full max-w-md bg-white rounded-xl shadow-2xl overflow-hidden transform transition-all">
          {/* Orange accent header */}
          <div className="bg-gradient-to-r from-orange-500 to-orange-400 py-4 px-6">
            <h3
              className="text-xl font-bold text-white"
              id="salon-not-found-title"
            >
              Salon introuvable
            </h3>
          </div>

          {/* Content */}
          <div className="p-6">
            <div className="flex items-start">
              <div className="flex-shrink-0 mr-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-12 text-orange-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <p className="text-gray-600 leading-relaxed">
                Nous n'avons pas pu trouver le salon que vous recherchez. Il a
                peut-être été supprimé ou le lien est incorrect.
              </p>
            </div>

            {/* Footer with button */}
            <div className="mt-8 flex justify-end">
              <button
                onClick={() => (window.location.href = "/")}
                className="px-5 py-2.5 bg-orange-500 hover:bg-orange-600 text-white font-medium rounded-lg text-sm transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-offset-2"
              >
                Retour à l'accueil
              </button>
            </div>
          </div>
        </div>
      </div>

      {salon && (
        <>
          {/* Hero Section */}

          <div className="hero-section bg-gradient-to-r from-orange-100/50 to-orange-300 text-white py-12 px-4 md:px-8">
            <div className="container mx-auto px-4 py-6">
              <div className="space-y-4">
                <h1 className="text-3xl font-bold text-gray-900">
                  {salon?.name}
                </h1>

                <div className="flex flex-wrap gap-6 text-gray-600">
                  <div className="flex items-center gap-2">
                    <MapPin className="w-5 h-5 text-orange-500" />
                    <span>
                      {salon?.address}, {salon?.city}
                    </span>
                  </div>

                  <div className="flex items-center gap-1">
                    {[...Array(fullStars)].map((_, index) => (
                      <Star
                        key={`full-${index}`}
                        className="w-5 h-5 fill-yellow-400 text-yellow-400"
                      />
                    ))}
                    {hasHalfStar && (
                      <Star className="w-5 h-5 text-yellow-400" />
                    )}
                    {[...Array(emptyStars)].map((_, index) => (
                      <Star
                        key={`empty-${index}`}
                        className="w-5 h-5 text-gray-300"
                      />
                    ))}
                    <span className="ml-2">
                      ({salon?.user?.reviews?.length})
                    </span>
                  </div>

                  <div className="flex items-center gap-2">
                    <Clock className="w-5 h-5 text-green-500" />
                    <span>
                      Ouvert Demain: {salon?.startTime} - {salon?.endTime}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Image Slider */}
          <div className="image-slider py-10 bg-white-100 px-4">
            <div className="container mx-auto">
              <Slider {...settings}>
                {salon?.album?.map((item, index) => (
                  <div key={index} className="px-2">
                    <Zoom>
                      <img
                        src={`${BASE_URL_IMAGE}${item.image}`}
                        alt={`Salon view ${index + 1}`}
                        className="rounded-lg shadow-md w-full h-64 object-cover"
                      />
                    </Zoom>
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          {/* Services Quick View */}
          <div className="services-quick-view py-8 bg-white px-4">
            <div className="container mx-auto flex gap-4 overflow-x-auto pb-4">
              {salon?.services?.slice(0, 5)?.map((item, index) => {
                const IconComponent = iconMap[item?.icon];
                return (
                  <div
                    key={index}
                    className="service-chip flex items-center gap-2 bg-orange-100 px-4 py-2 rounded-full whitespace-nowrap"
                  >
                    {IconComponent && (
                      <IconComponent
                        className="text-orange-500"
                        fontSize="small"
                      />
                    )}
                    <span className="text-black">{item.name}</span>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Main Content */}
          <div className="main-content container mx-auto px-4 py-12">
            {/* Description Section */}
            <div className="description-section mb-12">
              <h2 className="text-2xl text-black font-bold mb-6 border-b pb-2">
                Description
              </h2>
              <div dangerouslySetInnerHTML={{ __html: salon?.description }} />
            </div>

            {/* Services Section */}

            <div className="services-section mb-12">
              <h2 className="text-2xl text-black font-bold mb-6 border-b pb-2 mb-4">
                Services
              </h2>
              <ServicesTabsComp services={salon?.services} salon={salon} />
            </div>

            {/* Reviews Section */}
            <div className="reviews-section mb-12">
              <div className="flex items-center justify-between mb-6 flex-wrap gap-4">
                <h2 className="text-2xl text-black font-bold w-full md:w-auto">
                  Avis sur l'établissement
                </h2>
                <div className="flex items-center gap-2 flex-wrap">
                  <span className="text-3xl font-bold text-orange-600">
                    {salon?.average_rating || "0,0"}
                  </span>
                  <div className="flex flex-col">
                    {/* <div className="flex gap-1">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="w-4 h-4 text-yellow-400" />
                  ))}
                </div> */}
                    <div className="flex items-center gap-1">
                      {[...Array(fullStars)].map((_, index) => (
                        <Star
                          key={`full-${index}`}
                          className="w-5 h-5 fill-yellow-400 text-yellow-400"
                        />
                      ))}
                      {hasHalfStar && (
                        <Star className="w-5 h-5 text-yellow-400" />
                      )}
                      {[...Array(emptyStars)].map((_, index) => (
                        <Star
                          key={`empty-${index}`}
                          className="w-5 h-5 text-gray-300"
                        />
                      ))}
                    </div>
                    <span className="text-sm text-gray-600">
                      {salon?.user?.reviews?.length} avis
                    </span>
                  </div>
                </div>
              </div>

              <div className="grid md:grid-cols-3 gap-8">
                <div className="space-y-2">
                  <div className="bg-gray-50 rounded-lg p-4 space-y-4">
                    <h3 className="text-xl text-black font-bold">
                      Filtrer par type de prestation
                    </h3>
                    <ReviewFilter
                      selectedService={selectedService}
                      setSelectedService={setSelectedService}
                      services={salon?.services}
                    />

                    <h3 className="text-xl text-black font-bold">
                      Filtrer par évaluation
                    </h3>
                    <ReviewFilterTwo
                      selectedRating={selectedRating}
                      setSelectedRating={setSelectedRating}
                    />
                  </div>

                  <div className="flex flex-col items-start gap-2 bg-green-50 rounded-lg p-4">
                    <div className="flex gap-3 items-center">
                      <BadgeCheck className="w-7 h-7 text-green-500 flex-shrink-0" />
                      <h3 className="text-2xl font-bold text-black">
                        Avis vérifiés
                      </h3>
                    </div>
                    <div>
                      <p className="text-sm text-gray-600 mt-1">
                        Rédigé par nos clients, pour vous faire une idée de la
                        qualité du salon.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="md:col-span-2">
                  <ReviewList reviews={filteredReviews} />
                </div>
              </div>
            </div>

            {/* Team Section */}
            <div className="team-section mb-12">
              <div className="team-heading mb-6">
                <h2 className="text-2xl text-black font-bold border-b pb-2">
                  Rencontrez l'équipe
                </h2>
              </div>
              <EmployeeRatings employees={salon?.employees} salon={salon} />
            </div>

            {/* Map Section */}
            <div className="map-section">
              <div className="map-heading mb-6">
                <h2 className="text-2xl text-black font-bold border-b pb-2">
                  Notre localisation
                </h2>
              </div>

              <div className="map-details flex flex-col md:flex-row gap-6 mb-6">
                <div className="address-card p-4 rounded-lg w-full md:w-1/3">
                  <div className="flex items-start gap-3">
                    <MapPin className="mt-1 w-6 h-6 text-orange-500 flex-shrink-0" />
                    <div>
                      <h3 className="font-medium text-orange-800">
                        {salon?.name}
                      </h3>
                      <p className="text-gray-600 mt-1">
                        {salon?.address}, {salon?.city}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="map-container w-full md:w-2/3 h-80 rounded-lg overflow-hidden border border-gray-300">
                  {salon && mapPosition[0] !== 0 && mapPosition[1] !== 0 ? (
                    <MapContainer
                      center={mapPosition}
                      zoom={15}
                      style={{ height: "100%", width: "100%" }}
                    >
                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                      <Marker position={mapPosition} icon={icon}>
                        <Popup>{salon?.name}</Popup>
                      </Marker>
                    </MapContainer>
                  ) : (
                    <div className="flex items-center justify-center h-full bg-gray-100">
                      <p className="text-gray-500 text-center p-4">
                        Map location unavailable
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Floating Reservation Bar */}
          <div className="fixed z-10000 bottom-0 left-0 right-0 ">
            {reservation?.services?.length &&
            reservation?.salon?.id === parseInt(id) ? (
              <div className="bg-white shadow-lg border-t">
                {/* fixed z-100000  */}
                <div className="container mx-auto px-4 py-4 flex items-center justify-between flex-col md:flex-row">
                  <div className="mb-4 md:mb-0">
                    <div className="flex items-center gap-2">
                      <span className="font-bold text-xl text-gray-800">
                        {reservation?.services?.length || 0} prestation
                      </span>
                      <span className="font-bold text-lg text-orange-600">
                        {totalPrice} Dhs
                      </span>
                    </div>
                    <p className="text-sm text-gray-800">
                      Vous pouvez ajouter d'autres prestations ou continuer
                    </p>
                  </div>

                  <button
                    onClick={() =>
                      (window.location.href = `/availability?id=${id}`)
                    }
                    className="bg-orange-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-orange-700 transition-colors flex items-center gap-2 w-full md:w-auto"
                  >
                    Je choisis mon horaire
                    <ChevronRight className="w-5 h-5" />
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default DetailPage;
