import { ADD_AVAILABILITY, ADD_SALON, ADD_SERVICES, CLEAR_RESERVATION, INIT_BOOKING } from "../constants/actions";

const initialState = {
  salon: null,
  services: [],
  date: null,
  start_time: null,
  end_time: null,
  employee_id: null
};

const reservation = (state = initialState, action) => {
  const { type, payload } = action;
  if(type === CLEAR_RESERVATION){
    return { ...state, services: [] };
  }
     
  if (type === ADD_SERVICES) {
    return { ...state, services: payload };
  } 
  else if (type === ADD_SALON){
    return { ...state, salon: payload };
  }
  else if (type === ADD_AVAILABILITY){
    return {
      ...state,
      date: payload?.date,
      start_time: payload?.start_time,
      end_time: payload?.end_time,
      employee_id: payload?.employee_id,
    };
  }
  else if(type === INIT_BOOKING){
    return {
      salon: null,
      services: [],
      date: null,
      start_time: null,
      end_time: null,
      employee_id: null
    }
  }
  else {
    return state;
  }
};

export default reservation;
