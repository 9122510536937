import React, { useEffect, useState, useCallback, useMemo } from "react";
import "./style.scss";
import DoneIcon from "@mui/icons-material/Done";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircularProgress from "@mui/material/CircularProgress";

function DatePickerCust({
  setDateTimeRange,
  reservation,
  emplAvailabilities,
  selectedEmpl,
}) {
  const { date, start_time, services, salon } = reservation;

  const getStartOfWeek = (date) => {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  };

  const [currentWeekStart, setCurrentWeekStart] = useState(() =>
    getStartOfWeek(new Date(date || new Date()))
  );
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const weekdays = useMemo(
    () => ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    []
  );
  const months = useMemo(
    () => [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    []
  );

  const initializeSelectedDay = useCallback(
    (targetDate) => {
      const dateObj = new Date(targetDate);
      return {
        date: dateObj.getDate(),
        day: weekdays[dateObj.getDay()],
        fullDate: dateObj,
      };
    },
    [weekdays]
  );

  useEffect(() => {
    if (date) {
      setCurrentWeekStart(getStartOfWeek(new Date(date)));
      setSelectedDay(initializeSelectedDay(date));
    } else {
      const firstAvailableDay = getFirstAvailableDay(currentWeekStart);
      if (firstAvailableDay) {
        setSelectedDay(firstAvailableDay);
      } else {
        handleNextWeek();
      }
    }
  }, [date, initializeSelectedDay]);

  const updateDateTimeRange = useCallback(
    (day, slot) => {
      if (day && slot) {
        const startTime = slot.time + ":00";
        const endTime = new Date(`1970-01-01T${slot.time}`);
        endTime.setMinutes(endTime.getMinutes() + 30);
        const endTimeStr = endTime.toTimeString().slice(0, 5) + ":00";

        const dateTimeRange = {
          date: day.fullDate.toISOString().split("T")[0],
          start_time: startTime,
          end_time: endTimeStr,
        };

        setDateTimeRange(dateTimeRange);
      }
    },
    [setDateTimeRange]
  );

  useEffect(() => {
    updateDateTimeRange(selectedDay, selectedSlot);
  }, [selectedDay, selectedSlot, updateDateTimeRange]);

  const getFirstAvailableDay = useCallback(
    (weekStart) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      for (let i = 0; i < 7; i++) {
        const currentDate = new Date(weekStart);
        currentDate.setDate(weekStart.getDate() + i);

        if (currentDate >= today) {
          return initializeSelectedDay(currentDate);
        }
      }

      return null;
    },
    [initializeSelectedDay]
  );

  const handleNextWeek = useCallback(() => {
    setIsLoading(true);
    setTimeout(() => {
      setCurrentWeekStart((prevStart) => {
        const newStart = new Date(prevStart);
        newStart.setDate(prevStart.getDate() + 7);
        return newStart;
      });
      setSelectedDay(null);
      setSelectedSlot(null);
      setIsLoading(false);
    }, 300);
  }, []);

  const handlePrevWeek = useCallback(() => {
    setIsLoading(true);
    setTimeout(() => {
      setCurrentWeekStart((prevStart) => {
        const newStart = new Date(prevStart);
        newStart.setDate(prevStart.getDate() - 7);
        return newStart;
      });
      setSelectedDay(null);
      setSelectedSlot(null);
      setIsLoading(false);
    }, 300);
  }, []);

  const handleDayClick = useCallback((day) => {
    if (isDaySelectable(day)) {
      setSelectedDay(day);
      setSelectedSlot(null);
    }
  }, []);

  const isSlotDisabled = useCallback(
    (slot) => {
      if (!selectedDay) return { disabled: true, message: "" };

      const now = new Date();
      const selectedDate = new Date(selectedDay?.fullDate);
      const [slotHours, slotMinutes] = slot?.time?.split(":").map(Number);
      const slotStartTime = new Date(selectedDate);
      slotStartTime.setHours(slotHours, slotMinutes, 0, 0);

      if (slotStartTime < now) {
        return { disabled: true, message: "Passé" };
      }

      const totalDuration = services?.reduce((sum, service) => {
        const durationStr = service?.pivot?.duration;
        const hours = parseInt(durationStr?.slice(0, 2), 10);
        const minutes = parseInt(durationStr?.slice(2), 10);
        return sum + hours * 60 + minutes;
      }, 0);

      const slotEndTime = new Date(slotStartTime);
      slotEndTime.setMinutes(slotEndTime.getMinutes() + totalDuration);

      const endOfDay = new Date(selectedDate);
      endOfDay.setHours(24, 0, 0, 0);

      const selectedDateStr = selectedDay?.fullDate.toISOString()?.split("T")[0];
      let isInsufficientTime = false;
      let isOverlapping = false;
      let earliestOverlapStart = null;

      for (const avail of emplAvailabilities || []) {
        if (avail?.date !== selectedDateStr) continue;

        const [startHours, startMinutes] = avail?.start_time?.split(":").map(Number);
        const [endHours, endMinutes] = avail?.end_time?.split(":").map(Number);

        const reservationStart = new Date(selectedDate);
        reservationStart.setHours(startHours, startMinutes, 0, 0);

        const reservationEnd = new Date(selectedDate);
        reservationEnd.setHours(endHours, endMinutes, 0, 0);

        if (endHours === 0 && endMinutes === 0) {
          reservationEnd.setHours(24, 0, 0, 0);
        }

        if (
          (slotStartTime < reservationEnd && slotEndTime > reservationStart) ||
          (slotStartTime >= reservationStart && slotStartTime < reservationEnd) ||
          (slotEndTime > reservationStart && slotEndTime <= reservationEnd)
        ) {
          isOverlapping = true;
          if (!earliestOverlapStart || reservationStart < earliestOverlapStart) {
            earliestOverlapStart = reservationStart;
          }
        }
      }

      if (slotEndTime > endOfDay) {
        isInsufficientTime = true;
      }

      if (isOverlapping && earliestOverlapStart) {
        if (slotStartTime < earliestOverlapStart && slotEndTime > earliestOverlapStart) {
          return { disabled: true, message: "Temps insuffisant" };
        } else {
          return { disabled: true, message: "Indisponible" };
        }
      }

      if (isInsufficientTime) return { disabled: true, message: "Temps insuffisant" };

      return { disabled: false, message: "" };
    },
    [selectedDay, services, emplAvailabilities]
  );

  const handleSlotClick = useCallback(
    (slot) => {
      const { disabled } = isSlotDisabled(slot);
      if (!disabled) {
        setSelectedSlot(slot);
      }
    },
    [isSlotDisabled]
  );

  const generateCalendar = useCallback(() => {
    const calendar = [];
    const startOfWeek = new Date(currentWeekStart);

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startOfWeek);
      currentDate.setDate(startOfWeek.getDate() + i);
      calendar.push(initializeSelectedDay(currentDate));
    }

    return calendar;
  }, [currentWeekStart, initializeSelectedDay]);

  const generateTimeSlots = useCallback((startTime = salon?.startTime || "08:30", endTime = salon?.endTime || "23:30") => {
    const timeSlots = [];
    const intervalMinutes = 30;

    const [startHour, startMinute] = startTime?.split(':').map(Number);
    const [endHour, endMinute] = endTime?.split(':').map(Number);

    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minute = (hour === startHour ? startMinute : 0); minute < 60; minute += intervalMinutes) {
        if (hour === endHour && minute > endMinute) break;

        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        timeSlots.push({ time });
      }
    }

    return timeSlots;
  }, []);

  const calendar = useMemo(() => generateCalendar(), [generateCalendar]);
  const timeSlots = useMemo(() => generateTimeSlots(), [generateTimeSlots, selectedDay]);

  useEffect(() => {
    if (start_time && timeSlots?.length > 0 && selectedDay) {
      const [hours, minutes] = start_time.split(":");
      const formattedTime = `${hours}:${minutes}`;
      const foundSlot = timeSlots.find(slot => slot.time === formattedTime);

      const reservationDate = new Date(date);
      if (foundSlot && selectedDay.fullDate.toISOString().split("T")[0] === reservationDate.toISOString().split("T")[0]) {
        setSelectedSlot({ time: formattedTime });
      } else {
        setSelectedSlot(null);
      }
    }
  }, [start_time, timeSlots, selectedDay, date]);

  const isDaySelectable = useCallback((day) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return day.fullDate >= today;
  }, []);

  return (
    <div className="date-picker">
      <div className="calendar">
        <div className="month-row">
          <button
            className="arrow-button"
            onClick={handlePrevWeek}
            disabled={
              !getFirstAvailableDay(
                new Date(currentWeekStart?.getTime() - 7 * 24 * 60 * 60 * 1000)
              )
            }
          >
            <ArrowBackIosIcon />
          </button>
          <div className="month">
            {months[currentWeekStart?.getMonth()]}{" "}
            {currentWeekStart?.getFullYear()}
          </div>
          <button
            className="arrow-button"
            onClick={handleNextWeek}
          >
            <ArrowForwardIosIcon />
          </button>
        </div>
        <div className="weeks">
          {calendar?.map((day, index) => (
            <div
              key={index}
              className={`day ${isDaySelectable(day) ? "" : "disabled"}`}
              onClick={() => handleDayClick(day)}
            >
              <div
                className={`date ${
                  selectedDay &&
                  selectedDay?.date === day?.date &&
                  selectedDay?.fullDate?.getMonth() ===
                    day?.fullDate?.getMonth()
                    ? "active"
                    : ""
                }`}
              >
                {day?.date}
              </div>
              <div className="day-label">{day?.day}</div>
            </div>
          ))}
        </div>
        <div className="time-slots">
          {isLoading ? (
            <div className="loading-spinner">
              <CircularProgress />
            </div>
          ) : (
            timeSlots?.map((slot, index) => {
              const { disabled, message } = isSlotDisabled(slot);
              return (
                <div
                  key={index}
                  className={`time-slot ${
                    selectedSlot && selectedSlot?.time === slot?.time
                      ? "active"
                      : ""
                  } ${disabled ? "disabled" : ""}`}
                  onClick={() => handleSlotClick(slot)}
                >
                  <span className="time">{slot?.time}</span>{" "}
                  {disabled && <span className="status-message">{message}</span>}
                  {selectedSlot &&
                  selectedSlot?.time === slot?.time &&
                  !disabled ? (
                    <DoneIcon className="done-icon" />
                  ) : (
                    ""
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default DatePickerCust;