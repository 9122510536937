import React, { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Mail,
  Lock,
  Eye,
  EyeOff,
  CheckCircle,
  AlertCircle,
  Loader2,
} from "lucide-react";
import { loginClient } from "../../actions/auth";
import { apiClient } from "../../actions/api";
import { LOGIN_CLIENT } from "../../constants/actions";
import MobileRegister from "../mobileRegister";

function MobileLogin() {
  const [formData, setFormData] = useState({});
  let stepform = localStorage.getItem("stepform") || "login";
  const [resetStep, setResetStep] = useState(stepform);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [codeVerification, setCodeVerification] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showSPassword, setShowSPassword] = useState(false);
  const [isLoading, setIsLoading] = useState({
    login: false,
    forgotPassword: false,
    verifyCode: false,
    resetPassword: false,
    googleSignIn: false
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isRegistrationMode, setIsRegistrationMode] = useState(false);
  const location = useLocation();

  
  // Check authentication status on component mount
useEffect(() => {
  // Don't redirect if we're intentionally in registration mode
  if (isRegistrationMode) return;
  
  // Check for existing authentication
  const checkAuthStatus = () => {
    const isAuth = localStorage.getItem('authenticated') === 'true';
    const user = JSON.parse(localStorage.getItem('user'));
    
    if (isAuth && user) {
      dispatch({ type: LOGIN_CLIENT, payload: user });
      navigate("/");
    }
  };
  
  checkAuthStatus();
  
  // Also set up a short interval to check auth status for the first few seconds
  // This helps with token-based auth that might happen slightly after mount
  const authCheckInterval = setInterval(checkAuthStatus, 1000);
  
  // Clear interval after 5 seconds
  setTimeout(() => clearInterval(authCheckInterval), 5000);
  
  return () => clearInterval(authCheckInterval);
}, [dispatch, navigate, isRegistrationMode]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("mode") === "register") {
      setIsRegistrationMode(true);
    }
    if (params.get("mode") === "connexion") {
      setIsRegistrationMode(false);
    }
  }, [location]);

  // Add this to the top of your useEffect for Google token handling in MobileLogin.js
useEffect(() => {
  // Add a more reliable way to detect and process tokens
  const processStoredGoogleToken = () => {
    const storedToken = sessionStorage.getItem('googleToken');
    if (storedToken) {
      console.log("Found token in sessionStorage, processing...");
      // Add a delay to ensure component is fully mounted
      setTimeout(() => {
        handleGoogleTokenSignIn(storedToken);
        // Don't remove the token yet - only after successful authentication
      }, 800);
    }
  };

  // Process on mount
  processStoredGoogleToken();

  // Set up a global token handler for React Native to call
  window.setGoogleToken = (token) => {
    if (token) {
      sessionStorage.setItem('googleToken', token);
      handleGoogleTokenSignIn(token);
    }
  };

  return () => {
    // Clean up
    delete window.setGoogleToken;
  };
}, []);


  useEffect(() => {
    // Listen for messages from the React Native WebView
    const handleMessage = (event) => {
      try {
        const data = typeof event.data === "string" ? JSON.parse(event.data) : event.data;
        
        // Handle the Google Sign-In token
        if (data.type === 'googleSignInToken' && data.token) {
          console.log("Received Google token from WebView:", data.token);
          // Add a small delay to ensure UI has updated
          setTimeout(() => {
            handleGoogleTokenSignIn(data.token);
          }, 300);
        }
      } catch (error) {
        console.error("Error processing message from WebView:", error);
      }
    };
  
    // Add the event listener
    document.addEventListener('message', handleMessage);
    window.addEventListener('message', handleMessage);

    setTimeout(() => {
      const storedToken = sessionStorage.getItem('googleToken');
      if (storedToken) {
        console.log("Found token in sessionStorage:", storedToken);
        handleGoogleTokenSignIn(storedToken);
        // Clear it to avoid repeated processing
        sessionStorage.removeItem('googleToken');
      }
    }, 500);
    
    // Also check URL parameters
    const searchParams = new URLSearchParams(window.location.search);
    const token2Param = searchParams.get("token2");
    if (token2Param) {
      console.log("Found token2 in URL:", token2Param);
      setTimeout(() => {
        handleGoogleTokenSignIn(token2Param);
      }, 300);
    }
    
    // Clean up
    return () => {
      document.removeEventListener('message', handleMessage);
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleSetStep = (step) => {
    localStorage.setItem("stepform", step);
    setResetStep(step);
  };

  const handleGoogleButtonClick = (e) => {
    e.preventDefault();
    // Send message to React Native
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'initiateGoogleSignIn' })
      );
    } else {
      console.log('Not running in React Native WebView');
    }
  };

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('authenticated') === 'true';
    const user = JSON.parse(localStorage.getItem('user')) || null;
  
    if (isAuthenticated && user) {
      dispatch({ type: LOGIN_CLIENT, payload: user });
      sessionStorage.removeItem('googleToken');
      navigate("/"); // Redirect to home if authenticated
    }
  }, []);
  
  const handleGoogleTokenSignIn = async (token) => {
    if (!token) {
      toast.error("La connexion avec Google a échoué");
      return;
    }
  
    // Prevent duplicate processing
    if (isLoading.googleSignIn) return;
    
    setIsLoading(prev => ({ ...prev, googleSignIn: true }));
  
    try {
      console.log("Processing Google sign-in with token:", token.substring(0, 10) + "...");
      const result = await apiClient().post('/auth/google', { token });
      
      if (result?.data?.data?.user) {
        // Clear token from sessionStorage AFTER successful authentication
        sessionStorage.removeItem('googleToken');
        
        dispatch({ type: LOGIN_CLIENT, payload: result.data.data.user });
        
        // Save authentication state to localStorage
        localStorage.setItem('authenticated', 'true');
        localStorage.setItem('user', JSON.stringify(result.data.data.user));
        
        // Notify React Native
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: 'authenticationSuccess' })
          );
        }
  
        toast.success("Connexion réussie avec Google !");
        
        // Use a more reliable navigation approach with a small delay
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        toast.error(result?.data?.message || "Échec de la connexion avec Google");
      }
    } catch (error) {
      console.error("Google sign-in error:", error);
      toast.error("Une erreur s'est produite lors de la connexion avec Google");
    } finally {
      setIsLoading(prev => ({ ...prev, googleSignIn: false }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading((prev) => ({ ...prev, login: true }));

    if (localStorage.getItem("type_push")) {
      if (localStorage.getItem("typepush") == "ios") {
        formData.push_ios = localStorage.getItem("token_push");
      } else {
        formData.push_android = localStorage.getItem("token_push");
      }
    }

    try {
      const response = await dispatch(loginClient(formData));
      if (response.success) {
        // Store authentication state
        localStorage.setItem('authenticated', 'true');
        
        // Signal to React Native
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: 'authenticationSuccess' })
          );
        }
        
        toast.success("Connexion réussie !", {
          icon: <CheckCircle className="text-green-500" />,
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        });
        
        // Delay navigation slightly to ensure state is updated
        setTimeout(() => {
          navigate("/");
        }, 500);
      } else {
        toast.error("Échec de connexion: " + response.message, {
          icon: <AlertCircle className="text-red-500" />,
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        });
      }
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.", {
        icon: <AlertCircle className="text-red-500" />,
        style: { borderRadius: "10px", background: "#333", color: "#fff" },
      });
    } finally {
      setIsLoading((prev) => ({ ...prev, login: false }));
    }
  };

  // Other handler functions remain the same...
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading((prev) => ({ ...prev, forgotPassword: true }));

    try {
      const response = await apiClient().post("/forgot-password", {
        email: forgotPasswordEmail,
      });
      if (response?.status === 200) {
        toast.success("Un e-mail de réinitialisation a été envoyé.", {
          icon: <CheckCircle className="text-green-500" />,
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        });
        handleSetStep("enterCode");
      } else {
        toast.error("E-mail non trouvé", {
          icon: <AlertCircle className="text-red-500" />,
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        });
      }
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.", {
        icon: <AlertCircle className="text-red-500" />,
        style: { borderRadius: "10px", background: "#333", color: "#fff" },
      });
    } finally {
      setIsLoading((prev) => ({ ...prev, forgotPassword: false }));
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    try {
      apiClient()
        .post("/check-code", {
          email: forgotPasswordEmail,
          resetNumber: codeVerification,
        })
        .then((response) => {
          if (response?.data?.status) {
            toast.success("Code vérifié avec succès !", {
              icon: <CheckCircle className="text-green-500" />,
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            handleSetStep("newPassword");
          } else {
            toast.error("Le code de réinitialisation est incorrect", {
              icon: <AlertCircle className="text-red-500" />,
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            handleSetStep("enterCode");
          }
        });
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.", {
        icon: <AlertCircle className="text-red-500" />,
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      console.error(error);
    }
  };

  const handleNewPasswordSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPass) {
      toast.error("Les mots de passe ne correspondent pas !", {
        icon: <AlertCircle className="text-red-500" />,
        style: { borderRadius: "10px", background: "#333", color: "#fff" },
      });
      return;
    }

    setIsLoading((prev) => ({ ...prev, resetPassword: true }));

    try {
      const response = await apiClient().post("/reset-password", {
        email: forgotPasswordEmail,
        password: formData.password,
      });
      if (response.data.status) {
        toast.success("Mot de passe réinitialisé avec succès !", {
          icon: <CheckCircle className="text-green-500" />,
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        });
        setFormData({ ...formData, password: "" });
        handleSetStep("login");
      } else {
        toast.error("Échec de la réinitialisation", {
          icon: <AlertCircle className="text-red-500" />,
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        });
      }
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.", {
        icon: <AlertCircle className="text-red-500" />,
        style: { borderRadius: "10px", background: "#333", color: "#fff" },
      });
    } finally {
      setIsLoading((prev) => ({ ...prev, resetPassword: false }));
    }
  };

  const renderForm = () => {
    switch (resetStep) {
      case "login":
        return (
          <div className="min-h-screen bg-[#fd8000] flex flex-col justify-center items-center px-4 w-full">
            <div className="w-full max-w-md mx-auto">
              <h2 className="text-2xl font-bold mb-8 text-center text-white">
                Connexion
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="relative">
                  <input
                    type="email"
                    placeholder="Email"
                    className="w-full py-4 pl-12 pr-4 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
                    value={formData.email || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                    required
                  />
                  <Mail
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
                    size={22}
                  />
                </div>

                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Mot de passe"
                    className="w-full py-4 pl-12 pr-12 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
                    value={formData.password || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                    required
                  />
                  <Lock
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
                    size={22}
                  />
                  <button
                    type="button"
                    className="absolute right-4 top-1/2 transform -translate-y-1/2"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <EyeOff className="text-white" size={24} />
                    ) : (
                      <Eye className="text-white" size={24} />
                    )}
                  </button>
                </div>

                <div className="text-right">
                  <button
                    type="button"
                    className="text-white font-semibold"
                    onClick={() => handleSetStep("forgotPassword")}
                  >
                    Mot de passe oublié ?
                  </button>
                </div>

                <button
                  type="submit"
                  disabled={isLoading.login}
                  className={`w-full bg-white text-[#fd8000] py-4 rounded-xl uppercase font-bold ${
                    isLoading.login
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-white/90"
                  } transition duration-300 flex items-center justify-center`}
                >
                  {isLoading.login ? (
                    <Loader2 className="animate-spin" size={24} />
                  ) : (
                    "Connexion"
                  )}
                </button>
              </form>

              <div className="mt-4 mb-4">
                <div className="relative">
                  <div className="flex items-center justify-center">
                    <div className="w-1/4 h-px bg-white/30"></div>
                    <span className="mx-4 text-white/70">Ou</span>
                    <div className="w-1/4 h-px bg-white/30"></div>
                  </div>
                </div>
              </div>

              {/* Custom Google Sign-In button instead of the standard GSI button */}
              <button
                  onClick={handleGoogleButtonClick}
                  disabled={isLoading.googleSignIn}
                  className="w-full bg-white text-gray-700 py-4 rounded-xl font-medium flex items-center justify-center mb-4"
                  style={{ width: '100%' }} // Add explicit style
                >
                {isLoading.googleSignIn ? (
                  <Loader2 className="animate-spin" size={24} />
                ) : (
                  <>
                    <svg width="24" height="24" className="mr-2" viewBox="0 0 24 24">
                      <path
                        fill="#4285F4"
                        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                      />
                      <path
                        fill="#34A853"
                        d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                      />
                      <path
                        fill="#FBBC05"
                        d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                      />
                      <path
                        fill="#EA4335"
                        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                      />
                    </svg>
                    Continuer avec Google
                  </>
                )}
              </button>

              <div className="text-center">
                <div className="mt-4 text-white">
                  Vous n'avez pas encore de compte ?
                  <button
                    onClick={() => navigate("/connexion?mode=register")}
                    className="ml-2 font-semibold"
                  >
                    Créer un compte
                  </button>
                </div>
              </div>
            </div>
          </div>
        );

      case "forgotPassword":
        return (
          <div className="min-h-screen bg-[#fd8000] flex flex-col justify-center items-center px-4 w-full">
            <div className="w-full max-w-md mx-auto">
              <h2 className="text-2xl font-bold mb-8 text-center text-white">
                Mot de passe oublié
              </h2>
              <form onSubmit={handleForgotPassword} className="space-y-4">
                <div className="relative">
                  <input
                    type="email"
                    placeholder="Email"
                    className="w-full py-4 pl-12 pr-4 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
                    value={forgotPasswordEmail || ""}
                    onChange={(e) => setForgotPasswordEmail(e.target.value)}
                    required
                  />
                  <Mail
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
                    size={22}
                  />
                </div>
                <button
                  type="submit"
                  disabled={isLoading.forgotPassword}
                  className={`w-full bg-white text-[#fd8000] py-4 rounded-xl uppercase font-bold ${
                    isLoading.forgotPassword
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-white/90"
                  } transition duration-300 flex items-center justify-center`}
                >
                  {isLoading.forgotPassword ? (
                    <Loader2 className="animate-spin" size={24} />
                  ) : (
                    "Envoyer l'email"
                  )}
                </button>
              </form>
              <div className="mt-6 text-center">
                <button
                  onClick={() => handleSetStep("login")}
                  className="text-white font-semibold"
                >
                  Retour à la connexion
                </button>
              </div>
            </div>
          </div>
        );

      case "enterCode":
        return (
          <div className="min-h-screen bg-[#fd8000] flex flex-col justify-center items-center px-4 w-full">
            <div className="w-full max-w-md mx-auto">
              <h2 className="text-2xl font-bold mb-8 text-center text-white">
                Réinitialisation du mot de passe
              </h2>
              <form onSubmit={handleVerifyCode} className="space-y-4">
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="Entrez le code"
                    id="code"
                    className="w-full py-4 pl-12 pr-12 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
                    value={codeVerification}
                    onChange={(e) => setCodeVerification(e.target.value)}
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="w-full text-[#fd8000] bg-white py-3 rounded-lg hover:bg-orange-600 font-bold uppercase transition duration-300"
                >
                  Vérifier
                </button>
              </form>
            </div>
          </div>
        );

      case "newPassword":
        return (
          <div className="min-h-screen bg-[#fd8000] flex flex-col justify-center items-center px-4 w-full">
            <div className="w-full max-w-md mx-auto">
              <h2 className="text-2xl font-bold mb-8 text-center text-white">
                Nouveau mot de passe
              </h2>
              <form onSubmit={handleNewPasswordSubmit} className="space-y-4">
                <div className="mb-4 relative">
                  <div className="relative">
                    <input
                      type={showSPassword ? "text" : "password"}
                      placeholder="Nouveau mot de passe"
                      id="password"
                      className="w-full py-4 pl-12 pr-4 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
                      value={formData?.password || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                      required
                    />
                    <Lock
                      className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
                      size={20}
                    />
                    <button
                      type="button"
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                      onClick={() => setShowSPassword(!showSPassword)}
                    >
                      {showSPassword ? (
                        <EyeOff className="text-white" size={22} />
                      ) : (
                        <Eye className="text-white" size={22} />
                      )}
                    </button>
                  </div>
                </div>

                <div className="mb-4 relative">
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Confirmer mot de passe"
                      id="confirmPassword"
                      className="w-full py-4 pl-12 pr-4 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
                      value={formData?.confirmPass || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          confirmPass: e.target.value,
                        })
                      }
                      required
                    />
                    <Lock
                      className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
                      size={20}
                    />
                    <button
                      type="button"
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <EyeOff className="text-white" size={20} />
                      ) : (
                        <Eye className="text-white" size={20} />
                      )}
                    </button>
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full text-[#fd8000] uppercase font-bold bg-white py-3 rounded-lg hover:bg-orange-600 transition duration-300"
                >
                  Réinitialiser
                </button>
              </form>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return isRegistrationMode ? (
    <div className="w-full max-w-screen-lg mx-auto bg-[#fd8000]">
      <MobileRegister />
    </div>
  ) : (
    <div className="w-full max-w-screen-lg mx-auto bg-[#fd8000]">
      {renderForm()}
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 4000,
          success: {
            style: {
              background: "white",
              color: "black",
            },
          },
          error: {
            style: {
              background: "white",
              color: "black",
            },
          },
        }}
      />
    </div>
  );
}

export default MobileLogin;