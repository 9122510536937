// import React, { useState, useEffect } from "react";
// import Footer from "../footer";
// import HeaderMinimal from "../headerminimal";
// import Headertwo from "../headertwo";
// import { FooterMobile, HeaderMobile } from "..";

// const Component = ({
//   component: Component,
//   protect,
//   parts,
//   footer = true,
//   ads,
//   showheader,
//   showfooter,
//   showheadermin,
//   ...rest
// }) => {
//   const [platform, setPlatform] = useState('web');

//   useEffect(() => {
//     function handleEvent(message) {
//       setPlatform(message.data);
//     }
//     document.addEventListener("message", handleEvent);

//     return () =>
//       document.removeEventListener("message", handleEvent);
//   }, []);


//   const RenderComponent = (props) => {
//     return (
//       <>
//         {/* Your component logic here */}
//         <Component {...props} />
//       </>
//     );
//   };

//   return (
//     <>
//       {/* Conditional rendering for headers */}
//       {showheader && (platform !== "web" ? <HeaderMobile /> : <Headertwo />)}
//       {showheadermin && <HeaderMinimal />}

//       {/* Main content */}
//       <main>
//         { platform !== "web" && <div className="mt-20"></div> }
//         <RenderComponent {...rest} />
//         { platform !== "web" && <div className="mb-20"></div> }
//       </main>

//       {/* Conditional rendering for footers */}
//       {showfooter && (platform !== "web" ? <FooterMobile /> : <Footer />)}
//     </>
//   );
// };

// export default Component;

import React, { useState, useEffect } from "react";
import Footer from "../footer";
import HeaderMinimal from "../headerminimal";
import Headertwo from "../headertwo";
import { FooterMobile, HeaderMobile } from "..";
import { useSelector, useDispatch } from "react-redux";
import { MobileLogin, MobileLunch } from "../../pages";
import { useNavigate } from "react-router-dom";

const CustomRoute = ({
  component: Component,
  protect,
  parts,
  footer = true,
  ads,
  showheader,
  showfooter,
  showheadermin,
  showminheadermob,
  showwitharrow,
  addbitheader,
  ...rest
}) => {
  const [platform, setPlatform] = useState("web");
  const client = useSelector((state) => state?.auth?.client);
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  useEffect(() => {
    function handleEvent(event) {
      try {
        const message = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
        
        if (message.type === 'PLATFORM_DETECTION' && message.platform) {
          setPlatform(message.platform);
        }

        if (token) {
          localStorage.setItem('token_push', token);
          localStorage.setItem('type_push', message.platform);
        }
      
      } catch (error) {
        console.error('Error parsing platform message:', error);
      }
    }
  
    // Add both document and window event listeners
    document.addEventListener('message', handleEvent);
    window.addEventListener('message', handleEvent);
  
    // Fallback platform detection
    if (!platform) {
      const detectedPlatform = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) 
        ? ('android' || 'ios')
        : 'web';
      setPlatform(detectedPlatform);
    }
  
    return () => {
      document.removeEventListener('message', handleEvent);
      window.removeEventListener('message', handleEvent);
    };
  }, [platform]);

  const dispatch = useDispatch();

  // Check authentication state more reliably
  useEffect(() => {
    const checkAuthState = () => {
      const isAuth = localStorage.getItem('authenticated') === 'true';
      const user = JSON.parse(localStorage.getItem('user'));
      
      if (isAuth && user && !client) {
        // Dispatch login action if needed
        // (You'll need to import useDispatch for this)
        
        dispatch({ type: 'LOGIN_CLIENT', payload: user });
      }
      
      setLoading(false);
    };
    
    checkAuthState();
    // Check again after a short delay (handles race conditions)
    const timer = setTimeout(checkAuthState, 1000);
    
    return () => clearTimeout(timer);
  }, [client]);

  const RenderComponent = (props) => {
    if (loading) {
      return <div>Loading...</div>; // Show a loading indicator during auth check
    }
    // Allow MobileLogin component to render even without client
    if (Component === MobileLogin) {
      return <Component {...props} />;
    }
    
    // For other components, maintain the existing logic
    if (platform !== "web" && !client) {
      return <MobileLunch />;
    }
  
    return <Component {...props} />;
  };

  const isLoginPage = platform !== "web" && !client;
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  }    
  return (
    <>
      {/* Conditional rendering for headers */}
      {!isLoginPage && showheader && (platform !== "web" ? <HeaderMobile handleGoBack={handleBack} showminheadermob={showminheadermob} showwitharrow={showwitharrow} /> : <Headertwo />)}
      {!isLoginPage && showheadermin && (platform !== "web" ? <HeaderMobile handleGoBack={handleBack} showminheadermob={showheadermin} showwitharrow={showwitharrow} /> : <HeaderMinimal />)}

      {/* Main content */}
      <main>
        {(platform !== "web" && !isLoginPage) && (showheader || showminheadermob || showheadermin ? <div className="h-20"></div> : "")}
        {(platform !== "web" && !isLoginPage) && addbitheader ? <div className="h-2"></div> : <div></div>}
        <RenderComponent {...rest} />
        {(platform !== "web" && !isLoginPage) && <div className="mb-20"></div>}
      </main>

      {/* Conditional rendering for footers */}
      {!isLoginPage && showfooter && (platform !== "web" ? <FooterMobile /> : <Footer />)}
    </>
  );
};

export default CustomRoute;