import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton
} from '@mui/material';
import { Check as CheckIcon, X, AlertCircle } from 'lucide-react';

const PaymentResultModal = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isSuccess = searchParams.get('success') === 'true';
  const showModal = searchParams.has('success');

  const handleClose = () => {
    navigate('/');
  };

  const handleFailureClose = () => {
    navigate('/checkout');
  };

  // For failure case: Auto redirect after 10 seconds
  useEffect(() => {
    let redirectTimer;
    if (showModal && !isSuccess) {
      redirectTimer = setTimeout(() => {
        handleFailureClose();
      }, 10000);
    }
    
    return () => {
      if (redirectTimer) clearTimeout(redirectTimer);
    };
  }, [showModal, isSuccess]);

  return (
    <Dialog 
      open={showModal} 
      onClose={isSuccess ? handleClose : handleFailureClose}
      maxWidth="sm"
      fullWidth
    >
      <Box position="absolute" right={8} top={8}>
        <IconButton onClick={isSuccess ? handleClose : handleFailureClose} size="small">
          <X size={20} />
        </IconButton>
      </Box>

      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        pt={3}
        px={3}
      >
        {isSuccess ? (
          // Success content
          <>
            <Box 
              sx={{
                backgroundColor: 'success.light',
                borderRadius: '50%',
                width: 64,
                height: 64,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 2
              }}
            >
              <CheckIcon color="white" size={32} />
            </Box>

            <DialogTitle sx={{ pb: 1 }}>
              <Typography variant="h5" align="center" fontWeight="bold">
                Paiement Réussi
              </Typography>
            </DialogTitle>

            <DialogContent>
              <Typography align="center" color="text.secondary" sx={{ mb: 3 }}>
                Votre réservation a été confirmée avec succès. Un email de confirmation vous sera envoyé prochainement.
              </Typography>
            </DialogContent>

            <DialogActions sx={{ pb: 3, px: 3, width: '100%', justifyContent: 'center', gap: 2 }}>
              <Button 
                variant="contained" 
                color="success"
                onClick={handleClose}
                sx={{ px: 4 }}
              >
                D'accord
              </Button>
              <Button 
                variant="outlined"
                color="success"
                onClick={handleClose}
                sx={{ px: 4 }}
              >
                Fermer
              </Button>
            </DialogActions>
          </>
        ) : (
          // Failure content
          <>
            <Box 
              sx={{
                backgroundColor: 'error.main',
                borderRadius: '50%',
                width: 64,
                height: 64,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 2
              }}
            >
              <AlertCircle color="white" size={32} />
            </Box>

            <DialogTitle sx={{ pb: 1 }}>
              <Typography variant="h5" align="center" fontWeight="bold">
                Échec du Paiement
              </Typography>
            </DialogTitle>

            <DialogContent>
              <Typography align="center" color="text.secondary" sx={{ mb: 3 }}>
                Votre paiement n'a pas pu être traité. Veuillez vérifier vos informations et réessayer.
              </Typography>
              <Typography align="center" variant="caption" color="error.main" sx={{ display: 'block' }}>
                Redirection automatique dans 10 secondes...
              </Typography>
            </DialogContent>

            <DialogActions sx={{ pb: 3, px: 3, width: '100%', justifyContent: 'center', gap: 2 }}>
              <Button 
                variant="contained" 
                color="error"
                onClick={handleFailureClose}
                sx={{ px: 4 }}
              >
                Réessayer
              </Button>
            </DialogActions>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default PaymentResultModal;